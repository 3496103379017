<script lang="ts">
    import { onMount, afterUpdate, tick } from 'svelte';
    import toast from 'svelte-french-toast';
    import axios from 'axios';
    import {
        Icon,
        PencilSquare,
        LockClosed,
        LockOpen,
        PaperAirplane,
        Document,
        Trash,
        Banknotes,
    } from 'svelte-hero-icons';
    import { Button } from '$lib/components/ui/button/index.js';
    import { Skeleton } from '$lib/components/ui/skeleton/index.js';
    import Pusher from 'pusher-js';
    import PusherBatchAuthorizer from 'pusher-js';
    import { Picker } from 'emoji-mart';
    import { Textarea } from '$lib/components/ui/textarea/index.js';
    import Dropzone from 'dropzone';
    import * as Dialog from '$lib/components/ui/dialog/index.js';
    import { Input } from '$lib/components/ui/input/index.js';
    import ConversationHeaderBox from '@/Components/messages/ConversationHeaderBox.svelte';
    import ConversationBox from '@/Components/messages/ConversationBox.svelte';
    import ConversationHeader from '@/Components/messages/ConversationHeader.svelte';
    import NewConversationHeader from '@/Components/messages/NewConversationHeader.svelte';
    import { checkPPVLimits } from '@/utility/ppv';

    export let data: any;
    interface Contact {
        last_message_sender_id: number;
        last_message: string;
        isSeen: number;
        created_at: string;
        message_date: string;
        sender_id: number;
        sender_name: string;
        sender_avatar: string;
        sender_role: number;
        receiver_id: number;
        receiver_name: string;
        receiver_avatar: string;
        receiver_role: number;
        contact_id: number;
    }

    let contacts: Contact[];
    // $: contacts;
    let newChat: boolean = false;
    let newChatUserId: string = '';
    let ChatLoader: boolean = true;
    let pusher: any;
    let pickerContainer: any;
    let pickerTrigger: any;
    let showPicker: boolean = false;
    let picker: any;
    let newMessage: string = '';
    let conversation: any = [];
    let activeConversationUserID: string = '';
    let activeConversationUserUserName: string = '';
    let activeConversationUserAvatar: string = '';
    let activeConversationUserName: string = '';
    let activeConversationUserProfileUrl: string = '';
    let uploadedFiles: { attachmentID: any; path: any; type: any; thumbnail: any }[] = [];
    let myDropzone: any = null;
    let isPaidMessage: boolean = false;
    let messagePrice: number = 5;
    let chatContainer: any;
    let deleteDialogOpen: boolean = false;
    let attachementUploadingDialogOpen: boolean = false;
    let dropzoneLoading: boolean = false;
    let deleteMsgID: string = 'false';
    $: hoveredMessageId = '';

    $: isVideo = ['avi', 'mp4', 'wmv', 'mpeg', 'm4v', 'moov', 'mov'];
    $: isAudio = ['mp3', 'wav', 'ogg'];
    $: isImage = ['png', 'jpg', 'jpeg'];

    let mobileShowChat: boolean = false;
    $: isMobile = false;

    $: if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
    }

    function fetchContacts(): void {
        axios
            .get(window.location.origin + '/my/messenger/fetchContacts')
            .then(function (response) {
                if (response.data.status === 'success') {
                    contacts = response.data.data.contacts;
                    if (response.data.data.contacts.length === 0) {
                        data.lastContactID = false;
                        !newChat ? (ChatLoader = true) : '';
                    }
                } else {
                }
            })
            .catch(function (error) {
                console.error('Error fetching contacts:', error);
            });
    }

    function boot(): void {
        Pusher.logToConsole =
            typeof data.messengerVars.pusherDebug !== 'undefined' ? data.messengerVars.pusherDebug : false;
        let params: any = {
            authorizer: PusherBatchAuthorizer,
            authDelay: 200,
            authEndpoint: '/my/messenger/authorizeUser',
            auth: {
                headers: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
                },
            },
        };
        if (data.socketsDriver === 'soketi') {
            params.wsHost = data.soketiHost;
            params.wsPort = data.soketiPort;
            params.forceTLS = data.useTSL ? true : false;
        } else {
            params.cluster = data.messengerVars.pusherCluster;
        }
        pusher = new Pusher(data.socketsDriver === 'soketi' ? data.key : data.pusherKey, params);
    }

    function togglePicker() {
        showPicker = !showPicker;
        if (showPicker && !picker) {
            initializePicker();
        } else {
            if (picker) {
                picker = null;
                if (pickerContainer && pickerContainer.childNodes) {
                    pickerContainer.innerHTML = '';
                }
            }
        }
    }

    function initializePicker() {
        picker = new Picker({
            onEmojiSelect: (emoji: any) => {
                newMessage = newMessage + emoji.native;
                showPicker = !showPicker;
                if (picker) {
                    picker = null;
                    if (pickerContainer && pickerContainer.childNodes) {
                        pickerContainer.innerHTML = '';
                    }
                }
            },
            data: async () => {
                const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');
                return response.json();
            },
            theme: document.documentElement.classList.contains('dark') ? 'dark' : 'light',
            onClickOutside: (event: any) => {
                if (!event.target.classList.contains('new-conversation-toggle')) {
                    showPicker = !showPicker;
                    picker = null;
                    if (pickerContainer && pickerContainer.childNodes) {
                        pickerContainer.innerHTML = '';
                    }
                }
            },
        });

        pickerContainer.appendChild(picker);
    }

    function fetchConversation(contactId: string) {
        ChatLoader = true;
        axios
            .get(`/my/messenger/fetchMessages/${contactId}`, { responseType: 'json' })
            .then(function (response) {
                const result = response.data;
                if (result.status === 'success') {
                    console.log(result.data.messages);
                    conversation = result.data.messages;
                    activeConversationUserID = contactId;
                    reloadConversationHeader();
                    ChatLoader = false;
                } else {
                    // messenger.state.contacts = result.data
                }
            })
            .catch(function (error) {
                console.error('Error fetching messages:', error);
            })
            .finally(function () {
                ChatLoader = false;
            });
    }

    function reloadConversationHeader() {
        if (typeof conversation[0] !== 'undefined') {
            const contact = conversation[0];
            activeConversationUserUserName =
                contact.receiver_id !== activeConversationUserID ? contact.sender.username : contact.receiver.username;
            activeConversationUserAvatar =
                contact.receiver_id !== activeConversationUserID ? contact.sender.avatar : contact.receiver.avatar;
            activeConversationUserName =
                contact.receiver_id !== activeConversationUserID
                    ? `${contact.sender.name} `
                    : `${contact.receiver.name}`;
            activeConversationUserProfileUrl =
                contact.receiver_id !== activeConversationUserID
                    ? contact.sender.profileUrl
                    : contact.receiver.profileUrl;
        }
    }

    function sendMessage() {
        if (!dropzoneLoading || newMessage.length > 0) {
            attachementUploadingDialogOpen = false;
            axios
                .post('/my/messenger/sendMessage', {
                    message: newMessage,
                    attachments: uploadedFiles,
                    receiverIDs: !newChat ? [activeConversationUserID] : [newChatUserId],
                    price: isPaidMessage ? messagePrice : 0,
                })
                .then(function (response) {
                    console.log(data);
                    console.log('data.messengerVars.lastContactID', data.messengerVars.lastContactID);
                    conversation.push(response.data.data.message);

                    newMessage = '';
                    clearMessagePrice();
                    if (newChat) {
                        newChat = false;
                        fetchContacts();
                        fetchConversation(newChatUserId);
                    } else {
                        // fetchConversation(activeConversationUserID);
                    }
                    data.lastContactID = true;

                    console.log('conversation ', conversation);
                })
                .catch(function (error) {
                    if (error.response) {
                    } else {
                    }
                });
        } else {
            attachementUploadingDialogOpen = true;
        }
    }

    const initUploader = () => {
        const selector = '.dropzone';
        // Check if Dropzone is already attached to the element
        if (!document.querySelector(selector)?.classList.contains('dz-clickable')) {
            myDropzone = new Dropzone(selector, {
                url: `/attachment/upload/message`,
                paramName: 'file',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
                },
                maxFilesize: data.mediaSettings.max_file_upload_size,
                addRemoveLinks: true,
                dictRemoveFile: 'x',
                acceptedFiles: data.mediaSettings.allowed_file_extensions,
                dictDefaultMessage: '',
                previewsContainer: '.dropzone-previews',
                autoProcessQueue: true,
                parallelUploads: 1,
                clickable: ['.file-upload-button'],
            });

            myDropzone.on('addedfile', (file: any) => {
                dropzoneLoading = true;
                console.log(dropzoneLoading);
            });

            myDropzone.on('success', (file: any, response: any) => {
                uploadedFiles.push({
                    attachmentID: response.attachmentID,
                    path: response.path,
                    type: response.type,
                    thumbnail: response.thumbnail,
                });
                file.upload.assetSrc = response.assetSrc;
                dropzoneLoading = false;
                console.log(dropzoneLoading);
            });

            myDropzone.on('removedfile', function (file: any) {
                removeAsset(file.upload.assetSrc);
            });

            myDropzone.on('error', (file: any, errorMessage: any) => {
                if (typeof errorMessage.errors !== 'undefined') {
                    Object.values(errorMessage.errors).forEach((error) => {
                        // toast.error(error)
                    });
                } else {
                    const message = errorMessage.message || errorMessage;
                    // toast.error(message)
                }
                myDropzone.removeFile(file);
            });

            document.querySelector('.dz-default.dz-message')?.setAttribute('hidden', 'true');
        }
    };

    const removeAsset = (file: any) => {};

    function saveMessagePrice() {
        if (!checkPPVLimits(messagePrice)) {
            return false;
        }
        isPaidMessage = true;
    }

    function clearMessagePrice() {
        isPaidMessage = false;
        messagePrice = 5;
    }

    function showMessageDeleteDialog(messageID: string) {
        deleteDialogOpen = true;
        deleteMsgID = messageID;
    }

    function deleteMessage() {
        axios
            .delete(`/my/messenger/delete/${deleteMsgID}`, { responseType: 'json' })
            .then(async function (response) {
                const result = response.data;
                if (result.status === 'success') {
                    let element = document.querySelector('*[data-messageid="' + deleteMsgID + '"]');
                    element?.remove();
                    fetchContacts();
                }
            })
            .catch(function (error) {
                console.error('Error fetching messages:', error);
            })
            .finally(function () {
                deleteDialogOpen = false;
            });
    }

    function initMarkAsSeen() {
        axios
            .post(`/my/messenger/markSeen`, { userID: activeConversationUserID })
            .then(function (response) {
                const result = response.data;
                if (result.status === 'success') {
                    fetchContacts();
                    const element = document.querySelector('.menu-notification-badge.chat-menu-count');
                    if (element instanceof HTMLElement) {
                        const currentCount = parseInt(element.innerHTML) || 0;
                        const newCount = currentCount - (result.data?.count ?? 0);

                        if (newCount > 0) {
                            element.classList.remove('d-none');
                            element.innerHTML = newCount.toString();
                        } else {
                            element.classList.add('d-none');
                        }
                    } else {
                        console.warn('Element not found or is not an HTMLElement');
                    }
                }
            })
            .catch(function (error) {
                console.error('Error marking messages:', error);
            })
            .finally(function () {
                deleteDialogOpen = false;
            });
    }

    onMount(() => {
        if (data.messengerVars.bootFullMessenger) {
            boot();
            fetchContacts();

            if (data.messengerVars.lastContactID !== false && data.messengerVars.lastContactID !== 0) {
                fetchConversation(data.messengerVars.lastContactID);
            }
            initUploader();
        }
        if (window.innerWidth < 767) {
            isMobile = true;
        }
    });
</script>

<svelte:head>
    <link rel="stylesheet" href="https://unpkg.com/dropzone@5/dist/min/dropzone.min.css" type="text/css" />
</svelte:head>

<div class="flex">
    <div
        class="min-h-[92vh] border-r-[1px] border-[#dee2e6] md:min-h-screen md:w-1/4"
        class:w-full="{isMobile && !mobileShowChat}"
        class:w-0="{isMobile && mobileShowChat}"
        class:overflow-x-hidden="{isMobile && mobileShowChat}"
        class:overflow-y-hidden="{isMobile}"
    >
        <div class="conversations-wrapper-header flex items-center justify-between pb-2 pr-1 pt-3">
            <h5 class="text-bold m-0 truncate pl-3">Contacts</h5>
            <Button
                variant="ghost"
                class="new-conversation-toggle px-2 py-1 hover:bg-transparent focus:bg-transparent"
                on:click="{() => {
                    newChat = !newChat;
                    mobileShowChat = !mobileShowChat;
                    console.log(newChat);
                    !data.lastContactID ? (ChatLoader = !newChat) : '';
                }}"
            >
                <Icon src="{PencilSquare}" size="{'24px'}" />
            </Button>
        </div>
        <div class="conversations-list">
            {#if contacts && contacts.length > 0}
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                {#each contacts as contact}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <div
                        class="contact-box flex w-full p-2 contact-{contact.contact_id} cursor-pointer gap-[10px]"
                        on:click="{() => {
                            fetchConversation(String(contact.contact_id));
                            mobileShowChat = true;
                            newChat = false;
                            data.lastContactID = true;
                        }}"
                        class:bg-[#e9ecef]="{!isMobile &&
                            contact.contact_id == parseInt(activeConversationUserID) &&
                            !newChat}"
                    >
                        <img
                            src="{contact.receiver_id === data.user.user_id
                                ? contact.sender_avatar
                                : contact.receiver_avatar}"
                            class="contact-avatar h-[50px] w-[50px] rounded-[100%]"
                            alt="avatar"
                        />
                        <div class="m-0 flex flex-1 flex-col justify-center truncate">
                            <div
                                class="contact-name m-0 truncate {contact.last_message_sender_id !==
                                    data.user.user_id && contact.isSeen === 0
                                    ? 'font-bold'
                                    : ''}"
                            >
                                {contact.receiver_id === data.user.user_id
                                    ? contact.sender_name
                                    : contact.receiver_name}
                            </div>
                            <small class="message-excerpt-holder flex truncate">
                                <span
                                    class="mr-1 text-[#6c757d] ${contact.last_message_sender_id !== data.user.user_id
                                        ? 'hidden'
                                        : ''}"
                                >
                                    You:
                                </span>
                                <div
                                    class="contact-message m-0 flex-1 truncate text-[#6c757d] ${contact.last_message_sender_id !==
                                        data.user.user_id && contact.isSeen === 0
                                        ? 'font-bold'
                                        : ''}"
                                >
                                    {contact.last_message}
                                </div>
                                <div class="flex">
                                    <div class="ml-1 font-bold">{contact.created_at !== null ? '∙' : ''}</div>
                                    {#if contact.created_at !== null}
                                        &nbsp; {contact.created_at}
                                    {/if}
                                </div>
                            </small>
                        </div>
                    </div>
                {/each}
            {:else if data.lastContactID == false}
                <div class="mb-3 mt-3 flex pl-3">
                    <span>Click the text bubble to send a new message.</span>
                </div>
            {:else}
                {#each Array(3) as _, i}
                    <div class="mx-3 mb-2 flex items-center space-x-4 border-[1px] px-2 py-3">
                        <Skeleton class="h-12 w-12 rounded-full" />
                        <div class="w-[80%] space-y-2">
                            <Skeleton class="h-4 w-[100%]" />
                            <Skeleton class="h-4 w-[100%]" />
                        </div>
                    </div>
                {/each}
            {/if}
        </div>
    </div>
    <div
        class="min-h-[92vh] flex-col md:flex md:min-h-screen md:w-3/4"
        class:w-full="{isMobile && mobileShowChat}"
        class:w-0="{isMobile && !mobileShowChat}"
        class:overflow-x-hidden="{isMobile && !mobileShowChat}"
    >
        {#if ChatLoader}
            <svelte:component this="{ConversationHeaderBox}" {data} />

            <svelte:component this="{ConversationBox}" {data} bind:newChat />
        {:else}
            {#if newChat}
                <svelte:component
                    this="{NewConversationHeader}"
                    bind:value="{newChat}"
                    bind:ChatLoader
                    bind:newChatUser="{newChatUserId}"
                    bind:mobileShowChat
                    {data}
                />
            {:else}
                <svelte:component
                    this="{ConversationHeader}"
                    activeUser="{[
                        activeConversationUserID,
                        activeConversationUserUserName,
                        activeConversationUserAvatar,
                        activeConversationUserName,
                        activeConversationUserProfileUrl,
                    ]}"
                    bind:mobileShowChat
                />
            {/if}
            <div
                class="conversation-content max-h-[73vh] min-h-[73vh] flex-1 overflow-auto px-3 pb-1 pt-4 md:max-h-[82vh] md:min-h-[82vh]"
                bind:this="{chatContainer}"
            >
                <!-- {#if (data.messengerVars.lastContactID !== false && data.messengerVars.lastContactID !== 0) || !newChat} -->
                {#if conversation.length !== 0 && !newChat}
                    {#each conversation as message}
                        {#if message.hasUserUnlockedMessage === false && message.price > 0 && message.sender_id !== data.user.user_id}
                            <div
                                class="no-gutters message-box flex w-full px-0 pb-1 pt-1 {message.sender_id ===
                                data.user.user_id
                                    ? 'sender flex flex-row-reverse pr-1'
                                    : 'pl-0'}"
                                data-messageid="{message.id}"
                                id="m-{message.id}"
                            >
                                <div
                                    class="paid-message-box message-box m-0 rounded-[18px] px-[12px] py-[9px] {message.sender_id ===
                                    data.user.user_id
                                        ? 'rounded-tr-none bg-gradient-to-r from-bmn-400 to-bmn-500 text-white'
                                        : 'rounded-tl-none bg-[#e9ecef] text-[#252f40]'} w-[400px]"
                                >
                                    <div
                                        class="d-flex mb-2 w-full {message.sender_id === data.user.user_id
                                            ? 'sender d-flex flex-row-reverse pr-1'
                                            : 'pl-0'}"
                                    >
                                        {message.message === null ? '' : message.message}
                                    </div>
                                    <div>
                                        {#each message?.attachments as attachment, index}
                                            {#if index === 0}
                                                <div class="lockedPreviewWrapper">
                                                    <!-- svelte-ignore a11y-missing-attribute -->
                                                    <img
                                                        class="card-img"
                                                        src="{attachment?.blurred_version?.startsWith('//')
                                                            ? attachment?.blurred_version?.substring(1)
                                                            : attachment?.blurred_version}"
                                                    />
                                                </div>
                                            {/if}
                                        {/each}

                                        <div class="flex-column-reverse flex">
                                            <Button class=" rounded-[100px]">
                                                Locked message Unlock for {data.currencySymbol}
                                                {message.price}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {:else if message.message != null}
                            <div
                                class="no-gutters message-box w-full px-0 pb-1 pt-1"
                                data-messageid="{message.id}"
                                id="m-{message.id}"
                            >
                                <div class="flex">
                                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                                    <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                                    <div
                                        class="flex w-full {message.sender_id === data.user.user_id
                                            ? 'sender flex flex-row-reverse pr-1'
                                            : 'pl-0'}"
                                        on:mouseover="{() => (hoveredMessageId = message.id)}"
                                        on:mouseout="{() => (hoveredMessageId = '')}"
                                    >
                                        <div
                                            class="message-bubble text-break m-0 rounded-[18px] px-[12px] py-[9px] {message.sender_id ===
                                            data.user.user_id
                                                ? 'rounded-tr-none bg-gradient-to-r from-bmn-400 to-bmn-500 text-white'
                                                : ' rounded-tl-none bg-[#e9ecef] text-[#252f40]'}"
                                        >
                                            {message.message}
                                        </div>
                                        <div class="message-actions-wrapper flex">
                                            {#if message.sender_id === data.user.user_id}
                                                <div class="pointer-cursor mr-2 flex items-center justify-center">
                                                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                                                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                                                    <div
                                                        class="to-tooltip message-action-button flex items-center justify-center {hoveredMessageId !==
                                                        message.id
                                                            ? 'hidden'
                                                            : ''}"
                                                        on:click="{() => showMessageDeleteDialog(message.id)}"
                                                    >
                                                        <Icon src="{Trash}" size="{'20px'}" />
                                                    </div>
                                                </div>

                                                {#if message.price > 0}
                                                    <div
                                                        class="mr-2 flex items-center justify-center {hoveredMessageId !==
                                                        message.id
                                                            ? 'hidden'
                                                            : ''}"
                                                    >
                                                        <div
                                                            class="to-tooltip message-action-button d-flex items-center justify-center"
                                                        >
                                                            <Icon src="{Banknotes}" size="{'20px'}" />
                                                        </div>
                                                    </div>
                                                {/if}
                                            {/if}
                                        </div>
                                    </div>
                                </div>
                                {#if message.attachments.length > 1}
                                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                                    <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                                    <div
                                        class="flex w-full {message.sender_id === data.user.user_id
                                            ? 'sender flex-row-reverse pr-1'
                                            : 'pl-0'}"
                                        on:mouseover="{() => (hoveredMessageId = message.id)}"
                                        on:mouseout="{() => (hoveredMessageId = '')}"
                                    >
                                        <div class="attachments-holder row no-gutters flex-row-reverse">
                                            {#each message.attachments as attachment}
                                                {#if isVideo.includes(attachment.type)}
                                                    <a href="{attachment.path}" rel="mswp" title="" class="mr-2 mt-2">
                                                        <div class="video-wrapper">
                                                            <video
                                                                class="video-preview"
                                                                src="{attachment.path}"
                                                                width="150"
                                                                height="150"
                                                                controls
                                                                autoplay
                                                                muted
                                                            ></video>
                                                        </div>
                                                    </a>
                                                {:else if isAudio.includes(attachment.type)}
                                                    <a
                                                        href="{attachment.path}"
                                                        rel="mswp"
                                                        title=""
                                                        class="mr-2 mt-2 flex items-center"
                                                    >
                                                        <div class="video-wrapper">
                                                            <audio
                                                                id="video-preview"
                                                                src="{attachment.path}"
                                                                controls
                                                                muted
                                                            ></audio>
                                                        </div>
                                                    </a>
                                                {:else if isImage.includes(attachment.type)}
                                                    <a href="{attachment.path}" rel="mswp" title="">
                                                        <img
                                                            src="{attachment.thumbnail}"
                                                            class="mr-2 mt-2"
                                                            alt="file thumbnail"
                                                        />
                                                    </a>
                                                {:else}
                                                    <img
                                                        src="{attachment.thumbnail}"
                                                        class="mr-2 mt-2"
                                                        alt="file thumbnail"
                                                    />
                                                {/if}
                                            {/each}
                                        </div>
                                        <!-- ${attachmentsHtml.length && isSender ? messageActions(true, message) : ''} -->
                                        <div class="message-actions-wrapper flex">
                                            {#if message.sender_id === data.user.user_id}
                                                <div class="pointer-cursor mr-2 flex items-center justify-center">
                                                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                                                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                                                    <div
                                                        class="to-tooltip message-action-button flex items-center justify-center {hoveredMessageId !==
                                                        message.id
                                                            ? 'hidden'
                                                            : ''}"
                                                        on:click="{() => showMessageDeleteDialog(message.id)}"
                                                    >
                                                        <Icon src="{Trash}" size="{'20px'}" />
                                                    </div>
                                                    {#if message.price > 0}
                                                        <div
                                                            class="mr-2 flex items-center justify-center {hoveredMessageId !==
                                                            message.id
                                                                ? 'hidden'
                                                                : ''}"
                                                        >
                                                            <div
                                                                class="to-tooltip message-action-button flex items-center justify-center"
                                                            >
                                                                <Icon src="{Banknotes}" size="{'20px'}" />
                                                            </div>
                                                        </div>
                                                    {/if}
                                                </div>
                                            {/if}
                                        </div>
                                    </div>
                                {/if}
                            </div>
                        {:else}
                            <!-- svelte-ignore a11y-no-static-element-interactions -->
                            <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                            <div
                                class="flex w-full {message.sender_id === data.user.user_id
                                    ? 'sender flex-row-reverse pr-1'
                                    : 'pl-0'}"
                                on:mouseover="{() => (hoveredMessageId = message.id)}"
                                on:mouseout="{() => (hoveredMessageId = '')}"
                            >
                                <div class="attachments-holder row no-gutters flex-row-reverse">
                                    {#each message.attachments as attachment}
                                        {#if isVideo.includes(attachment.type)}
                                            <a href="{attachment.path}" rel="mswp" title="" class="mr-2 mt-2">
                                                <div class="video-wrapper">
                                                    <video
                                                        class="video-preview"
                                                        src="{attachment.path}"
                                                        width="150"
                                                        height="150"
                                                        controls
                                                        autoplay
                                                        muted
                                                    ></video>
                                                </div>
                                            </a>
                                        {:else if isAudio.includes(attachment.type)}
                                            <a
                                                href="{attachment.path}"
                                                rel="mswp"
                                                title=""
                                                class="mr-2 mt-2 flex items-center"
                                            >
                                                <div class="video-wrapper">
                                                    <audio id="video-preview" src="{attachment.path}" controls muted
                                                    ></audio>
                                                </div>
                                            </a>
                                        {:else if isImage.includes(attachment.type)}
                                            <a href="{attachment.path}" rel="mswp" title="">
                                                <!-- svelte-ignore a11y-missing-attribute -->
                                                <img
                                                    src="{attachment.thumbnail}"
                                                    class="mr-2 mt-2 h-[120px] w-[120px] rounded-lg"
                                                />
                                            </a>
                                        {:else}
                                            <!-- svelte-ignore a11y-missing-attribute -->
                                            <img
                                                src="{attachment.thumbnail}"
                                                class="mr-2 mt-2 h-[120px] w-[120px] rounded-lg"
                                            />
                                        {/if}
                                    {/each}
                                </div>
                                <div class="message-actions-wrapper flex">
                                    {#if message.sender_id === data.user.user_id}
                                        <div class="pointer-cursor mr-2 flex items-center justify-center">
                                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                                            <!-- svelte-ignore a11y-no-static-element-interactions -->
                                            <div
                                                class="to-tooltip message-action-button flex items-center justify-center {hoveredMessageId !==
                                                message.id
                                                    ? 'hidden'
                                                    : ''}"
                                                on:click="{() => showMessageDeleteDialog(message.id)}"
                                            >
                                                <Icon src="{Trash}" size="{'20px'}" />
                                            </div>
                                            {#if message.price > 0}
                                                <div
                                                    class="mr-2 flex items-center justify-center {hoveredMessageId !==
                                                    message.id
                                                        ? 'hidden'
                                                        : ''}"
                                                >
                                                    <div
                                                        class="to-tooltip message-action-button d-flex items-center justify-center"
                                                    >
                                                        <Icon src="{Banknotes}" size="{'20px'}" />
                                                    </div>
                                                </div>
                                            {/if}
                                        </div>
                                    {/if}
                                </div>
                                <!-- ${attachmentsHtml.length && isSender ? messageActions(true, message) : ''} -->
                            </div>
                        {/if}
                    {/each}
                {/if}
                <!-- {:else} -->
                <!-- <div class="flex">
                        <div class="flex h-full items-center justify-center">
                            <span>👋 You got no messages yet.</span>
                            <span class="hidden md:block">&nbsp;Say 'Hi!' to someone!"</span>
                        </div>
                    </div> -->
                <!-- {/if} -->
            </div>
        {/if}
        <div class="dropzone-previews dropzone ppl-0 min-h-[auto!important] w-full border-0 pb-1 pr-0 pt-1"></div>
        <div
            class="conversation-writeup pointer-events-none mb-1 flex items-center pb-1 pt-1"
            class:pointer-events-none="{conversation.length == 0 && !newChat}"
            class:opacity-50="{conversation.length == 0 && !newChat}"
        >
            <div class="messenger-buttons-wrapper flex pl-2">
                <Button
                    variant="ghost"
                    class="new-conversation-toggle file-upload-button px-2 py-1 text-bmn-500 hover:bg-transparent hover:text-bmn-300 focus:bg-transparent"
                >
                    <Icon src="{Document}" size="{'24px'}" solid />
                </Button>
            </div>
            <form class="message-form flex-1">
                <div class="messageBoxInput-wrapper relative flex w-full flex-wrap items-stretch">
                    <Textarea
                        bind:value="{newMessage}"
                        on:click="{() => {
                            initMarkAsSeen();
                        }}"
                        class=" messageBoxInput h-[45px] max-h-[120px] min-h-[45px] w-full rounded-[30px] border-[1px] border-[#0000004D] px-[0.75rem]  py-[0.5rem] leading-[1.7rem]"
                        placeholder="Write a message.."
                    />
                    <div class="z-index-3 absolute right-[0.5rem] top-[0] flex items-center justify-center">
                        <Button
                            on:click="{togglePicker}"
                            bind:this="{pickerTrigger}"
                            variant="ghost"
                            class="new-conversation-toggle px-2 py-0 text-bmn-500 hover:bg-transparent hover:text-bmn-300 focus:bg-transparent"
                        >
                            😊
                        </Button>
                        <span id="emoji-container" class="absolute bottom-10 right-0" bind:this="{pickerContainer}"
                        ></span>
                    </div>
                </div>
            </form>
            <div class="messenger-buttons-wrapper flex">
                {#if data.creatorCanEarnMoney}
                    <Dialog.Root>
                        {#if isPaidMessage}
                            <Dialog.Trigger>
                                <Button
                                    variant="ghost"
                                    class="new-conversation-toggle px-2 py-1 text-bmn-500 hover:bg-transparent hover:text-bmn-300 focus:bg-transparent"
                                    on:click="{sendMessage}"
                                >
                                    <Icon src="{LockClosed}" size="{'24px'}" solid />
                                </Button>
                            </Dialog.Trigger>
                        {:else}
                            <Dialog.Trigger>
                                <Button
                                    variant="ghost"
                                    class="new-conversation-toggle px-2 py-1 text-bmn-500 hover:bg-transparent hover:text-bmn-300 focus:bg-transparent"
                                    on:click="{sendMessage}"
                                >
                                    <Icon src="{LockOpen}" size="{'24px'}" solid />
                                </Button>
                            </Dialog.Trigger>
                        {/if}
                        <Dialog.Content class="sm:max-w-[425px]">
                            <Dialog.Header>
                                <Dialog.Title>Set message price</Dialog.Title>
                                <Dialog.Description>Only Media Assets are locked, text is visible.</Dialog.Description>
                            </Dialog.Header>
                            <div class="grid gap-4 py-4">
                                <Input id="messagePrice" bind:value="{messagePrice}" type="number" />
                            </div>
                            <Dialog.Footer>
                                <Dialog.Close>
                                    <Button on:click="{clearMessagePrice}" variant="outline">CLEAR</Button>
                                </Dialog.Close>
                                <Dialog.Close>
                                    <Button on:click="{saveMessagePrice}">SAVE</Button>
                                </Dialog.Close>
                            </Dialog.Footer>
                        </Dialog.Content>
                    </Dialog.Root>
                {/if}
                <Button
                    variant="ghost"
                    class="new-conversation-toggle px-2 py-1 text-bmn-500 hover:bg-transparent hover:text-bmn-300 focus:bg-transparent"
                    on:click="{sendMessage}"
                >
                    <Icon src="{PaperAirplane}" size="{'24px'}" solid />
                </Button>
            </div>
        </div>
    </div>
</div>

<Dialog.Root bind:open="{deleteDialogOpen}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Delete message</Dialog.Title>
            <Dialog.Description>Are you sure you want to delete this message?</Dialog.Description>
        </Dialog.Header>
        <Dialog.Footer class="md:justify-center">
            <Dialog.Close>
                <Button on:click="{deleteMessage}">Delete</Button>
            </Dialog.Close>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>

<Dialog.Root bind:open="{deleteDialogOpen}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Delete message</Dialog.Title>
            <Dialog.Description>Are you sure you want to delete this message?</Dialog.Description>
        </Dialog.Header>
        <Dialog.Footer class="md:justify-center">
            <Dialog.Close>
                <Button on:click="{deleteMessage}">Delete</Button>
            </Dialog.Close>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>

<Dialog.Root bind:open="{attachementUploadingDialogOpen}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Confirm action</Dialog.Title>
            <Dialog.Description
                >Some attachments are still being uploaded. Are you sure you want to continue?'</Dialog.Description
            >
        </Dialog.Header>
        <Dialog.Footer class="md:justify-center">
            <Dialog.Close>
                <Button>Continue</Button>
            </Dialog.Close>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>
